var storageName = process.env.GATSBY_USER_STORAGE;
const expirationName = 'pogaexp';

const isBrowser = typeof window !== `undefined`;

let localStorage;

if (isBrowser) {
  localStorage = window.localStorage;
}

export const getUser = () => {
  return get(storageName, true);
};

export const getIdentified = () => {
  return get('port_woop_ident_user');
};

export const setIdentified = (val = '') => {
  set('port_woop_ident_user', val);
};

export const setUser = (user) => {
  const stringifyUser = JSON.stringify(user);
  set(storageName, stringifyUser);
};

export const clearUser = () => {
  if (!isBrowser) return;
  set(storageName, '');
  localStorage.removeItem(storageName);
  setIdentified();
};

export const setExp = (exp) => {
  set(expirationName, exp);
};

export const getExp = () => {
  return get(expirationName, false);
};

export const clearExp = () => {
  if (!isBrowser) return;
  set(expirationName, '');
  localStorage.removeItem(expirationName);
};

export const set = (item, val) => {
  if (!isBrowser) return;
  const toUse = typeof val !== 'string' ? JSON.stringify(val) : val;
  localStorage.setItem(item, toUse);
};

export const get = (item, obj) => {
  if (!isBrowser) return;
  if (!item) return '';

  const val = localStorage.getItem(item);

  return obj ? JSON.parse(val) : val;
};
