import React, { useEffect, useState } from 'react';
import logo from '../../images/logo.svg';
import { Link } from 'gatsby';
import * as fromStorage from '../../services/storage';

const Header = (props) => {
  let woopra = null;
  if (typeof window !== 'undefined') {
    woopra = window.woopra || null;
  }
  const [menuActived, setMenuActive] = useState(false);
  const [showUser, setShowUser] = useState(false);

  const consent = props.consent;
  const isLogged = !!props.logged;
  const defaultSections = { productTour: false, features: false };
  const { productTour, features } = props.sections || defaultSections;
  const appUrl = process.env.GATSBY_APP_URL;
  const tasksUrl = process.env.GATSBY_TASKS_URL;

  useEffect(() => {
    if (consent && consent === 'none') {
      return setShowUser(false);
    }

    setShowUser(true);
  }, [consent]);

  useEffect(() => {
    if (isLogged) {
      if (woopra) {
        console.log('Woopra.');
        const user = fromStorage.getUser();
        const identified = fromStorage.getIdentified();
        if (user) {
          if (user.email !== identified) {
            woopra.identify({
              id: user.id,
              email: user.email,
              name: user.name,
            });

            woopra.push();

            fromStorage.setIdentified(user.email);
          }
        }
      }
    }
  }, [isLogged]);

  const scrollTo = (ref) => {
    const el = ref.current;
    const headerTop = 50;
    window.scrollTo({
      top: el.offsetTop - headerTop,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onLogout = () => {
    if (woopra) {
      woopra.identify();
      woopra.push();
    }
    props.onLogout();
  };

  const toggleCookie = () => {
    if (props.toggleCookie) {
      props.toggleCookie();
    }
  };

  const registerClick = function (ev, where) {
    if (woopra) {
      console.log('Goes to: ' + where);
      woopra.track('go_to_' + where);
    }
  };

  return (
    <header className="header">
      <nav className="navbar is-uppercase is-fixed-top" role="main">
        <div className="portica-wrapper is-fullhd">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              <img src={logo} alt="logo" className="header__logo"></img>
              <h1 className="header__title is-inline-block is-size-5">
                Portica
              </h1>
            </Link>
            {showUser ? (
              <span className="header-buttons-container">
                {!isLogged ? (
                  <span className="header-buttons">
                    <Link
                      to="/sign-in"
                      className="button is-uppercase is-small"
                    >
                      Log In
                    </Link>
                    <a
                      href={`${appUrl}/users/create`}
                      className="button is-uppercase is-primary is-small"
                    >
                      Sign up
                    </a>
                  </span>
                ) : (
                  <span className="header-buttons logged-in">
                    <a
                      href={`${tasksUrl}`}
                      className="button is-uppercase is-secondary is-small"
                      onClick={(ev) => registerClick(ev, 'tasks')}
                    >
                      Tasks
                    </a>

                    <a
                      href={`${appUrl}`}
                      className="button is-uppercase is-primary is-small"
                      onClick={(ev) => registerClick(ev, 'projects')}
                    >
                      Projects
                    </a>

                    <button
                      type="button"
                      className="button is-logout is-small"
                      onClick={onLogout}
                    >
                      Logout
                    </button>
                  </span>
                )}
              </span>
            ) : (
              <span className="header-buttons">
                <button
                  type="button"
                  className="button is-uppercase is-primary is-small"
                  onClick={toggleCookie}
                >
                  Set cookies settings
                </button>
              </span>
            )}
          </div>
          <div
            id="top-navbar-menu"
            className={`navbar-menu ${menuActived ? 'is-active' : ''}`}
          >
            <div className="navbar-end is-size-6">
              <div className="navbar-item">
                {productTour && features && (
                  <span className="navbar-end__buttons">
                    <button
                      className="navbar-item remove-button-styles is-radiusless is-shadowless is-paddingless"
                      onClick={() => scrollTo(productTour)}
                    >
                      Product Tour
                    </button>
                    <button
                      className="navbar-item remove-button-styles is-radiusless is-shadowless is-paddingless"
                      onClick={() => scrollTo(features)}
                    >
                      Features
                    </button>
                  </span>
                )}

                {showUser ? (
                  <div>
                    {!isLogged ? (
                      <div className="buttons">
                        <Link to="/sign-in" className="button is-uppercase">
                          Log In
                        </Link>
                        <a
                          href={`${appUrl}/users/create`}
                          className="button is-uppercase is-primary"
                        >
                          Sign up
                        </a>
                      </div>
                    ) : (
                      <div className="buttons">
                        <a
                          href={`${tasksUrl}`}
                          className="button is-uppercase is-secondary"
                          onClick={(ev) => registerClick(ev, 'tasks')}
                        >
                          Go to Tasks
                        </a>

                        <a
                          href={`${appUrl}`}
                          className="button is-uppercase is-primary"
                          onClick={(ev) => registerClick(ev, 'projects')}
                        >
                          Go to My Projects
                        </a>

                        <button
                          type="button"
                          className="button is-logout"
                          onClick={onLogout}
                        >
                          Logout
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="cookie-button">
                    <button
                      type="button"
                      className="button is-uppercase is-primary"
                      onClick={toggleCookie}
                    >
                      Set cookies settings
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
