import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import { getConsent } from '../services/consent';
import SEO from '../components/seo';

const domain = 'www.' + process.env.GATSBY_SITE_DOMAIN;

const NotFound = () => {
  let woopra = null;
  if (typeof window !== 'undefined') {
    woopra = window.woopra || null;
  }
  const [consentStatus, setConsentStatus] = useState(getConsent());

  useEffect(() => {
    if (consentStatus === 'update-here') {
      if (woopra) {
        woopra.track('page-not-found', { location: window.location });
      }
    }
  }, [consentStatus]);

  return (
    <div className="has-navbar-fixed-top privacy-policy">
      <Helmet>
        <script>
          {consentStatus === 'update-here'
            ? `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2930840,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
            : 'console.log("No consent")'}
        </script>
      </Helmet>
      <SEO
        title="Page not found | Portica - All your projects in one place"
        description="Page not found. Portica is a all-in-one project manager, created by Architects for Architects and Design Professionals. A digital home for your projects"
      ></SEO>
      <div>
        <Header></Header>
        <div className="portica-wrapper">
          <div className="section">
            <div className="is-stretch">
              <h1 className="title is-size-1">404 - Not found</h1>
              <p>We're sorry!</p>
              <p>The page you're looking for doesn't exist.</p>
              <p>
                <a href={`//${domain}`}>Go to home</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
